@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Belleza&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --greenColor: #2AA100
}

h1 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
}

* {
    font-family: 'Belleza', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    box-sizing: border-box;
}

button {
  border: none;
  outline: none;
  background: none
}

a:visited {
  color: #000;
}

.greenButton {
  width: 100px;
  height: 30px;
  background-color: #2AA100;
  background-color: var(--greenColor);
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 5px #000;
  font-weight: bold;
  font-size: 16px
}

  .greenButton:hover {
    background-color: #32ba02;
  }

  .greenButton:active {
    box-shadow: 0px 0px 0px #000
  }

.changeButton {
  background-color: #2AA100;
  background-color: var(--greenColor);
  border-radius: 6px;
  padding: 2px;
  color: white;
  margin-left: 5px;
  box-shadow: 0px 1px 5px #000;
}

.changeButton.w100 {
  width: 100px;
  margin-top: 5px;
}

  .changeButton:hover {
    background-color: #32ba02;
  }

  .changeButton:active {
    box-shadow: 0px 0px 0px #000
  }

.deleteButton {
  background-color: darkred;
  border-radius: 6px;
  padding: 2px;
  color: white;
  margin-left: 5px;
  box-shadow: 0px 1px 5px #000;
}

  .deleteButton:hover {
    background-color: #32ba02;
  }

  .deleteButton:active {
    box-shadow: 0px 0px 0px #000
  }

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.navBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-bottom: 0.5px solid #a2a2a2;
}

  .navBar__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 80px;
    padding: 20px;
  }

  .navBar__logo {
    width: 200px;
  }

  .navBar__links {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navBar__link {
    font-weight: bold;
    font-size: 16px;
    margin-left: 15px;
  }

  .navBar__link:hover {
    color: #2AA100;
    color: var(--greenColor)
  }

  .navBar__container_mobileMenu {
    display: none
  }

  .navBar__container_mobileMenu_open {
    display: none
  }

  .navBar__container_mobileMenu_close {
    display: none
  }

.homeContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  background-color: white;
  border-right: 0.5px dotted #000;
  border-left: 0.5px dotted #000;
  padding: 10px;
  padding-top: 10px;
}

  .homeContainer__addNewItem {
    display: flex;
    flex-direction: column;
    border: 0.5px dashed #000;
    border-radius: 5px;
    width: 100%;
    padding: 5px
  }

    .homeContainer__addNewItem_row {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .homeContainer__addNewItem_row_heading {
      width: 100px
    }

    .homeContainer__addNewItem_column {
      display: flex;
      height: 200px;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: space-between;
    }

    .homeContainer__addNewItem_selectCategory {
      display: flex;
      margin-left: 10px;
      width: 80%;
      flex-wrap: wrap;
    }

    .homeContainer__addNewItem_selectCategory_item {
      display: flex;
      border: 0.5px solid #000;
      padding: 3px;
      border-radius: 3px;
      margin-top: 3px;
      margin-left: 3px;
    }

    .homeContainer__addNewItem_selectCategory_item_selected {
      background-color: #2AA100;
      background-color: var(--greenColor);
      color: white;
      display: flex;
      border: 0.5px solid #000;
      padding: 3px;
      border-radius: 3px;
      margin-top: 3px;
      margin-left: 3px;
    }

  .shopContainer__productArea {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: auto;
    min-height: 900px;
    margin-bottom: 100px;
  }

    .shopContainer__productArea_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      box-shadow: 0px 2px 5px 2px rgba(0,0,0,0.43);
      border-radius: 10px;
      padding: 5px;
      height: auto;
      margin-bottom: 15px;
    }

    .shopContainer__productArea_item:hover {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 2px 5px 2px rgba(42,161,0,0.58);
      border-radius: 10px;
      padding: 5px;
      margin-bottom: 15px;
    }

      .shopContainer__productArea_item_img {
        width: 80px;
        object-fit:contain;
        border-radius: 10px;
        margin-right: 3px
      }

      .shopContainer__productArea_item_description {
        display: flex;
        flex-direction: column
      }

      .shopContainer__productArea_item_description_boldText {
        font-size: 16px;
        font-weight: bold;
      }

      .shopContainer__productArea_item_description_normalText {
        font-size: 14px;
        font-weight: normal;
      }

      .shopContainer__productArea_item_description_categoryText {
        font-size: 14px;
        font-weight: normal;
      }

      .shopContainer__productArea_item_description_priceText {
        font-size: 14px;
        font-weight: bold;
        color: red
      }

      .imageRow {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
      }

      .addImage {
        display: flex;
        flex-direction: column;
        height: 60px;
        border: 2px solid #2AA100;
        border: 2px solid var(--greenColor);
        border-radius: 6px;
        align-items: flex-start;
        justify-content: center;
        margin-left: 3px
      }

      .infoArea {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
      }

      .changeDescription {
        display: flex;
        padding: 3px;
        border: 1px dotted #2AA100;
        border: 1px dotted var(--greenColor);
        border-radius: 6px;
        width: 600px;
        outline: none;
      }

      .changePrice {
        display: flex;
        padding: 3px;
        border: 1px dotted #2AA100;
        border: 1px dotted var(--greenColor);
        border-radius: 6px;
        width: 60px;
        outline: none
      }

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #2AA100;
  background-color: var(--greenColor);
}

  .footer__container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }

  .footer__contacts {
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 12px
  }

  .footer__social {
    width: 32px;
    height: 32px
  }

